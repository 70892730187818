.create-discussion-toolbar {
    background-color: 'white';
    border: none !important;
}



.ql-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}

.ql-send {
    display: flex;
    flex-direction: row;    
}