


.answer-editor{
    width: 100%;
}

.answer-editor .ql-editor{
    min-height:200px;
}


.answer-editor .ql-container {
    font-size: 16px;
    font-family: 'Roboto';
    background: transparent;
    color: #4a4a4a;
    border: none !important; 
}





