.login-form-container {
    max-width: 300px;
    width: 75%;
    height: 200px;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 20% 0px 0px;
    padding: 5px;
    align-content: space-around;
    border-radius: 16px;
    box-shadow: 1.41px 1.41px 10px rgba(0, 0, 0, 0.35);
    align-items: center;
}

.input-email {
    outline: none;
    border: none;
    width: 100%;
    padding: 0px 10px;
    background-color: transparent;
    font-size: medium;
}

.input-password {
    outline: none;
    border: none;
    width: 100%;
    padding: 0px 10px;
    background-color: transparent;
    font-size: medium;
}

.input-login-container {
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    margin: 10px 0px;
    padding: 6px;
    border-radius: 16px;
    width: 85%;

}

.submit-button {
    background-color: #c4c4c4;
    color: #ffffff;
    width: 50%;
    padding: 10px 16px;
    border-radius: 24px;
    justify-items: center;
    font-weight: 500;
    border: none;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.forgot-your-password {
    margin-top: 10px;
    color: #c4c4c4;
    font-size: 14px;
    text-align: center;
    font-family: Arial, sans-serif;
}