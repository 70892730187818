.comment-editor-container {
    display: flex;
    margin-top: 10px;
    border-radius: 10px;
    border: solid 1px #dedede;
    padding: 8px;
    align-items: center;
}

.input-container {
    border: none;
    padding: 5px;
    font-size: 15px;
    color: #4a4a4a;
    font-family: Roboto;
    width: 100%;
    ; 
    overflow: auto; 

    &:focus {
        border: 0 none #FFF;
        overflow: hidden;
        outline:none;
    }
}
