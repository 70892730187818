.answer-toolbar {
    width: 100%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    display: flex;
    flex-direction: row;
    background: transparent;
    justify-content: space-between;
    white-space: nowrap;
    background: #eaecec;
    margin: 0%;

}
.answer-toolbar.ql-snow{
    font-size: 14px;
    margin: 0%;
    border: none !important; 
    background: #ffffff !important; 

}

.ql-div {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    width: 100%; 
    color: red;
}

.ql-send {
    display: flex;
    flex-direction: row;    
}