.answer-container {
    font-family: Roboto, sans-serif;
    flex-direction: column;
    display: flex;
}

.custom-editor .ql-container {
    font-size: 16px;
    font-family: Roboto, sans-serif;

}

.answer-icon-container {
    display: flex;
}

.separator-container {
    border: 0.5px solid #d9d9d9;
    width: 100%;
}

.comments-container {
    width: 90%;
    align-self: flex-end;
}