.custom-editor .ql-editor {
    padding-top: 2px;
    padding-right: 0px;
    padding-left: 0px; 
    padding-bottom: 2px;

}
.custom-editor .ql-container {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    color: #4a4a4a;

}

