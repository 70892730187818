.ql-snow .ql-editor{
    height:200px;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    border-radius: 20px !important; 
    overflow-y: scroll;
}

.ql-snow{
    border: none !important; 

}
.ql-snow .ql-tooltip {
    left:  50% !important;
    transform: translateX(-50%) !important;;
  }
.mention-item{
    display: flex;
    flex-direction: row;
    align-content: start;
    align-items: center;
}
.mention-avatar{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.ql-mention-list-container{
    background-color:#fff;
    border:1px solid #f0f0f0;
    border-radius:4px;
    box-shadow:0 2px 12px 0 rgba(30,30,30,.08);
    overflow:auto;
    width:270px;
    z-index:9001}
    
    .ql-mention-loading{
        font-size:16px;
        line-height:44px;
        padding:0 20px;
        vertical-align:middle}
        
        .ql-mention-list{
            list-style:none;
            margin:0;
            overflow:hidden;
            padding:0}
            
            .ql-mention-list-item{
                cursor:pointer;
                font-size:16px;
                line-height:44px;
                padding:0 20px;
                vertical-align:middle}
                
                .ql-mention-list-item.disabled{cursor:auto}
                
                .ql-mention-list-item.selected{
                    background-color:#d3e1eb;
                    text-decoration:none}
                    .mention{
                        background-color:#d3e1eb;
                        border-radius:6px;
                        margin-right:2px;
                        padding:3px 0;
                        user-select:all;
                        width:65px}
                        .mention>span{margin:0 3px}

